import React, { useEffect, useState } from "react"

import Layout from "../components/layout"

const ScheduleAppointmentPage = () => {
  const [iframeLink, setIframeLink] = useState("")
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const appointmentId = urlParams.get("id")
    const owner = urlParams.get("owner")
    const free = urlParams.get("free")

    if (free) {
      setIframeLink(
        `https://app.acuityscheduling.com/schedule.php?owner=${owner}&appointmentType=${appointmentId}`
      )
    } else {
      setIframeLink(
        `https://app.acuityscheduling.com/catalog.php?owner=${owner}&action=addCart&clear=1&id=${appointmentId}`
      )
    }
  })

  const iframeStyle = {
    padding: "180px 0 0 0",
  }

  return (
    <Layout isDark="true">
      <iframe
        style={iframeStyle}
        src={iframeLink}
        title="Schedule Appointment"
        width="100%"
        height="1200"
      ></iframe>
    </Layout>
  )
}

export default ScheduleAppointmentPage
